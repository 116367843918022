import apiFetch, { tokenRefreshMiddleware } from "apiConnectors/fetchConnector";
import { Pagination, UUID } from "../types";
import {
  Index,
  IndexAssignment,
  IndexAssignmentPayload,
  IndexFulfillment,
  IndexManufacturer,
  IndexPackage,
  RequestIndexProduction,
} from "./models";
import { omit, dateFns } from "utilities";
import { queryFetch } from "apiConnectors/queryFetch";
import { ApiFetcher } from "hooks/createApiQuery";
import { indexesKeys } from "./keys";
import { Assign } from "utility-types";

const api = tokenRefreshMiddleware(apiFetch);

export const getIndexes = (search: string = "") =>
  api<Pagination<Index>>({
    method: "GET",
    url: "/indexes/items" + search,
  });

export const getIndex = (id: number | string) =>
  api<Index>({
    method: "GET",
    url: "/indexes/items/" + id,
  });

export const postIndex = (data: { [key: string]: any }) =>
  api<Index>({
    method: "POST",
    url: "/indexes/items",
    data,
  });

export const getIndexesFulfillment = (
  hackSearch: string = "",
): ApiFetcher<Pagination<IndexFulfillment>> => ({
  key: indexesKeys.indexesFulfillment(hackSearch.split("$")[2]),
  fetcher: () => {
    const [source, id, search] = hackSearch.split("$");
    return queryFetch({
      method: "GET",
      url: `/indexes/fulfilment/${source}/${id}` + search,
    });
  },
});

export const postAssignProduction = (
  source: "route" | "order-group",
  sourceId: number,
  data: { indexId: number },
) =>
  queryFetch<IndexFulfillment>({
    method: "POST",
    url: `/indexes/fulfilment/${source}/${sourceId}/assign-production`,
    data,
  });

export const postRequestProduction = (
  source: "route" | "order-group",
  sourceId: number,
  data: RequestIndexProduction[],
) =>
  queryFetch<IndexFulfillment[]>({
    method: "POST",
    url: `/indexes/fulfilment/${source}/${sourceId}/request-production`,
    data,
  });

export const postAssignReservation = (
  source: "route" | "order-group",
  sourceId: number,
  data: { indexId: number },
) =>
  queryFetch<IndexFulfillment>({
    method: "POST",
    url: `/indexes/fulfilment/${source}/${sourceId}/assign-reservations`,
    data,
  });

export const postIndexesCsv = (
  data: FormData,
  onUploadProgress: (progressEvent: ProgressEvent) => void,
) =>
  queryFetch<{ message: string }>({
    method: "POST",
    url: "/indexes/bulk-update-eans",
    data,
    headers: {
      "Content-Disposition": 'attachment; filename="filename.xlsm"',
    },
    onUploadProgress,
  });

export const patchIndex = (
  id: number | string,
  data: Partial<
    Assign<
      Pick<Index, "numberOfPackages" | "volume" | "isUsed" | "deliveryAmount" | "carryingAmount">,
      {
        manufacturingSchema: UUID;
        itemsInPackage: number;
      }
    >
  >,
) =>
  api<Index>({
    method: "PATCH",
    url: "/indexes/items/" + id,
    data,
  });

export const indexBulkUpdate = (data: { objects: number[]; weight?: string; volume?: string }) =>
  api<{ weight?: string | number; volume?: string | number }>({
    method: "PATCH",
    url: "/indexes/items/bulk-update",
    data,
  });

export const patchPackagesAmount = (data: { productId: number; numberOfPackages: number | null }) =>
  api<{ productId: number; numberOfPackages: number }>({
    method: "PATCH",
    url: "/products/update-indexes-number-of-packages",
    data,
  });

export const deleteIndex = (id: number | string) =>
  api<void>({
    method: "DELETE",
    url: "/indexes/items/" + id,
  });

export const createIndexesForProduct = (id: number | string) =>
  api<void>({
    method: "POST",
    url: "/products/create-indexes/" + id,
  });

export const addIndexPackage = (data: { package: number; index: number }) =>
  api<IndexPackage>({
    method: "POST",
    url: "/packages/assign",
    data,
  });

export const deleteIndexPackage = (data: { package: number; index: number }) =>
  api<void>({
    method: "DELETE",
    url: "/packages/assign",
    data,
  });

export const getIndexAssignments = (id: number | string) =>
  api<IndexAssignmentPayload>({
    method: "GET",
    url: `/indexes/items/${id}/assignments`,
  });

export const patchIndexAssignment = (assignment: Partial<IndexAssignment> & { id: number }) =>
  api<IndexAssignment>({
    method: "PATCH",
    url: `/indexes/assignments/${assignment.id}`,
    data: omit(assignment, ["id"]),
  });

export const deleteIndexAssignment = (id: number | string) =>
  api<void>({
    method: "DELETE",
    url: `/indexes/assignments/${id}`,
  });

export const postIndexAssignment = (
  assignment: Omit<IndexAssignment, "id" | "externalName" | "externalCode">,
) =>
  api<IndexAssignment>({
    method: "POST",
    url: `/indexes/assignments`,
    data: assignment,
  });

export const indexFileFactory = (() => {
  function getDate() {
    return dateFns.format(new Date(), "dd-MM-yyyy-HH-mm");
  }
  return {
    IndexesCsv: () => ({
      url: `/indexes/csv`,
      name: `indeksy_${getDate()}`,
    }),
    IndexesProductCsv: (search: number) => ({
      url: `/indexes/csv?product_ids=${search}`,
      name: `indeksy_dla_produktu_${search}_${getDate()}`,
    }),
    indexesPricesCsv: (search: string) => ({
      url: `/indexes/buying-price-with-packages-csv${search}`,
      name: `cennik_indeksow_${getDate()}`,
    }),
    indexesWithPackagesCsv: (search: string) => ({
      url: `/indexes/indexes-with-packages${search}`,
      name: `ceny_zakupowe_indeksow_${getDate()}`,
    }),
    packagesWithPricesAndStateCsv: (search: string) => ({
      url: `/indexes/packages-with-prices-and-state${search}`,
      name: `ceny_zakupowe_paczek_${getDate()}`,
    }),
    IndexesProductsAndProducersCsv: () => ({
      url: `/products/product-producer-csv`,
      name: `produkty_producenci_${getDate()}`,
    }),
  };
})();

export const getIndexesManufacturers = (search: string = ""): ApiFetcher<IndexManufacturer[]> => ({
  key: indexesKeys.indexesManufacturers(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/indexes/manufacturers" + search,
    }),
});

export const postBulkUpdateSchemas = (data: {
  productId: number;
  manufacturingSchemaId: string;
}) => {
  return queryFetch<{ message: string }>({
    method: "POST",
    url: "/products/add-manufacturing-schema-to-indexes",
    data: data,
  });
};

export const getIndexGreatestWeight = (): ApiFetcher<number> => ({
  key: indexesKeys.indexGreatestWeight(),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/indexes/greatest-weight",
    }),
});

export const patchIndexCarryingAmount = (data: {
  minWeight: number;
  maxWeight: number;
  carryingAmount: number;
}) =>
  queryFetch<void>({
    method: "PATCH",
    url: "/indexes/update-carrying-amount-base-on-weight",
    data,
  });

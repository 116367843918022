import { CommonError, DisabledOpacity, MockupLoader } from "components/utils";
import { DrawerRightPanel, RightPanelBody, RightPanelSection } from "components/utils/drawer";
import { useQuery } from "hooks";
import { assertIsDefined } from "utilities/assertIsDefined";
import { PanelHeader } from "./panelHeader/PanelHeader";
import { GeneralInfoSection } from "./generalInfoSection/GeneralInfoSection";
import { TriggerScrollContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { EmailsSection } from "./emailsSection/EmailsSection";
import { carrierOrdersActions } from "api/logistics/carrierOrders/actions";
import { dictToList } from "utilities";
import { CURRENCY_TYPE, countryCurrenciesFlags } from "CONSTANTS";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { RouteCost } from "components/common/routeCost/RouteCost";
import { InfoLabel } from "components/common/infoLabel/InfoLabel";
import { AmountDisplay } from "components/miloDesignSystem/molecules/amountDisplay";

export const RightPanel = () => {
  const { query, setQuery } = useQuery();
  const { panelId } = query;
  const close = () => setQuery({ ...(query ?? {}), panelId: "" });
  const { data: carrierOrder, error, isLoading } = carrierOrdersActions.useCarrierOrder(panelId, {
    enabled: Boolean(panelId),
  });
  const currenciesOptions = dictToList(countryCurrenciesFlags).map(({ key, value }) => ({
    icon: value,
    text: key,
    type: MenuItemType.ICON,
    value: key,
  }));

  if (error) {
    return (
      <DrawerRightPanel className="position-static">
        <CommonError status={error._httpStatus_} />
      </DrawerRightPanel>
    );
  }

  if (isLoading) {
    return (
      <DrawerRightPanel className="position-static">
        <div className="overflow-hidden h-100 position-relative">
          <MockupLoader type="drawer" />
        </div>
      </DrawerRightPanel>
    );
  }

  if (!panelId) return null;

  assertIsDefined(carrierOrder);

  return (
    <TriggerScrollContext>
      <DrawerRightPanel className="position-relative" key={panelId}>
        <PanelHeader carrierOrder={carrierOrder} close={close} />
        <DisabledOpacity disabled={isLoading}>
          <RightPanelBody>
            <GeneralInfoSection carrierOrder={carrierOrder} />
            <RightPanelSection padding="px-3 pt-2 pb-3" title="Kwota za trasę">
              <RouteCost
                amountProps={{
                  label: "kwota za trasę",
                  mutationHook: () =>
                    carrierOrdersActions.usePatchCarrierOrderRouteCost(carrierOrder),
                  transformQueryData: customRouteCost => ({
                    id: Number(carrierOrder.route.id),
                    toUpdate: {
                      customRouteCost,
                    },
                  }),
                  size: "small",
                  type: "number",
                  value: carrierOrder.routeCost.customRouteCost.amount ?? "",
                }}
                currencyProps={{
                  items: currenciesOptions,
                  label: "Waluta",
                  mutationHook: () =>
                    carrierOrdersActions.usePatchCarrierOrderRouteCost(carrierOrder),
                  selected: carrierOrder.routeCost.customRouteCost.currency,
                  transformQueryData: customRouteCostCurrency => ({
                    id: Number(carrierOrder.route.id),
                    toUpdate: {
                      customRouteCostCurrency: customRouteCostCurrency as CURRENCY_TYPE,
                    },
                  }),
                }}
                routeCost={carrierOrder.routeCost}
              />
              <div>
                <InfoLabel className="pb-2 pt-3" title="potwierdzona kwota">
                  <div className="d-flex flex-column gap-2">
                    {carrierOrder.paymentsSummaryForRoute.map(summary => (
                      <div className="d-flex align-items-center gap-2">
                        {summary.currency && (
                          <img alt="" src={countryCurrenciesFlags[summary.currency]} />
                        )}
                        <AmountDisplay
                          amount={String(summary.summaryConfirmedDelivery)}
                          currency={summary.currency}
                        />
                      </div>
                    ))}
                  </div>
                </InfoLabel>
              </div>
            </RightPanelSection>
            <EmailsSection carrierOrder={carrierOrder} />
          </RightPanelBody>
        </DisabledOpacity>
      </DrawerRightPanel>
    </TriggerScrollContext>
  );
};

import { orderFileFactory } from "api/orders/calls";
import {
  postRouteEmail,
  postRouteSms,
  postSingleRouteSms,
  routeFileFactory,
} from "api/routes/calls";
import {
  NoPhoneError,
  OrderPositionTypes,
  PostDeadline,
  PostRouteSmsErrorResponse,
  Route,
  SmsError,
} from "api/routes/models";
import editIcon from "assets/images/1.svg";
import viewPdfIcon from "assets/images/161.svg";
import printPdfIcon from "assets/images/162.svg";
import printOrderPdfIcon from "assets/images/163.svg";
import printOrderXmlIcon from "assets/images/164.svg";
import arrowImg from "assets/images/171.svg";
import sendSmsIcon from "assets/images/174.svg";
import deliveryConfirmedIcon from "assets/images/175.svg";
import viewLabelImg from "assets/images/263.svg";
import blueArrow from "assets/images/271b.svg";
import envelopeIcon from "assets/images/276.svg";
import cx from "classnames";
import { Button as DriverLocationButton } from "components/common/button";
import { Button } from "components/common/buttonLegacy";
import { CustomSMSModal } from "components/common/customSMSModal";
import { showCustomerLabel, showSalesAccountLabel } from "components/common/listRow";
import {
  ClickSessionMemorizer,
  CommonError,
  MockupLoader,
  StatusHandler,
  StatusHandlerHelpers,
} from "components/utils";
import { useDownloadFeedbackToastr } from "components/utils/downloadFeedback/DownloadFeedbackController";
import { DrawerRight, DrawerSection } from "components/utils/drawer";
import { DOMAIN } from "ENDPOINTS";
import { fileDownloader } from "fileDownloader";
import {
  useConfirmModal,
  useQuery,
  useSelector,
  useSettings,
  useStateModal,
  useToastr,
  useToggle,
} from "hooks";
import { useState } from "react";
import { Link } from "react-router-dom";
import { dateFns, getAnyErrorKey, orderArrayByIdSchema } from "utilities";
import { assertIsDefined } from "utilities/assertIsDefined";
import { getMarkerIcon } from "utilities/getMarkerIcon";
import { getOrderRedirectUrl } from "utilities/getOrderRedirectUrl";
import { useRouteHook } from "../../hooks";
import styles from "../RightPanel.module.css";
import { AutomaticSmsScheduleModal } from "./automaticSmsScheduleModal/AutomaticSmsScheduleModal";
import { ConfirmationDateModal } from "./confirmationDateModal/ConfirmationDateModal";
import { EmailForAllConfirmationDateModal } from "./confirmationDateModal/EmailForAllConfirmationDateModal";
import { SingleSmsConfirmationDateModal } from "./confirmationDateModal/SingleSmsConfirmationDateModal";
import { ConversationModal } from "./conversationModal/ConversationModal";
import { DriverLocationModal } from "./driverLocationModal/DriverLocationModal";
import { EmailNotificationsModal } from "./emailNotificationsModal/EmailNotificationsModal";
import { SendEmailButton } from "./SendEmailButton";
import { PostDeliveryInfo } from "../../../shared/PostDeliveryInfo";

type RouteOrder = Route["orders"][0];

export const PointsSection = () => {
  const { query } = useQuery();
  const { panelId } = query;

  const { data: route, isLoading: inProgress, error } = useRouteHook(panelId);

  const toastr = useToastr();
  const confirmModal = useConfirmModal();
  const { transport } = useSettings();
  const smsModal = useStateModal<string>();
  const conversationModal = useToggle();
  const emailNotificationsModal = useToggle();
  const confirmationDateModal = useToggle();
  const singleSmsConfirmationDateModal = useToggle();
  const emailForAllConfirmationDateModal = useToggle();
  const hasSmsService = useSelector(store => store.partials.configuration.hasSmsService);
  const hasDeadlineConfirmationDate = transport.hasDeadlineConfirmationDate;
  const hasSmsAutomaticallySendForOrdersOnRoute = transport.hasSmsAutomaticallySendForOrdersOnRoute;
  const options = transport.options;
  const downloadFeedbackToastr = useDownloadFeedbackToastr();
  const driverLocationModal = useToggle();
  const smsSchedulePreviewModal = useToggle();
  const [orderToNotify, setOrderToNotify] = useState<RouteOrder>();
  const configuration = useSelector(state => state.partials.configuration);

  const isAwaitingWarehouseDelivery = (order: RouteOrder): boolean => {
    return (
      order.warehouseDeliveryDetails &&
      !order.warehouseDeliveryDetails.isInWarehouse &&
      order.warehouseDeliveryDetails.date !== null
    );
  };

  const hasDaysToDeliveryLeft = (order: RouteOrder): boolean => {
    return order.warehouseDeliveryDetails.daysLeftToDelivery !== null;
  };

  const hasMoreThanZeroDaysToDeliveryLeft = (order: RouteOrder): boolean => {
    return (
      order.warehouseDeliveryDetails.daysLeftToDelivery !== null &&
      order.warehouseDeliveryDetails.daysLeftToDelivery > 0
    );
  };

  const hasZeroDaysToDeliveryLeft = (order: RouteOrder): boolean => {
    return (
      order.warehouseDeliveryDetails.daysLeftToDelivery !== null &&
      order.warehouseDeliveryDetails.daysLeftToDelivery === 0
    );
  };

  const hasLessThanZeroDaysToDeliveryLeft = (order: RouteOrder): boolean => {
    return (
      order.warehouseDeliveryDetails.daysLeftToDelivery !== null &&
      order.warehouseDeliveryDetails.daysLeftToDelivery < 0
    );
  };

  async function handleDownloadOrderPdf(order: RouteOrder, helpers: StatusHandlerHelpers) {
    const fileToastr = downloadFeedbackToastr.open({ type: "pdf" });
    helpers.startFetching();
    const { url, name } = orderFileFactory.orderPdf(order);
    const response = await fileDownloader({
      onProgress: fileToastr.updateProgress,
      url,
      name,
      type: "pdf",
    });
    helpers.stopFetching();
    if (response.status === "success") {
      fileToastr.lazyClose();
    }
  }

  async function handleDownloadOrderXml(order: RouteOrder, helpers: StatusHandlerHelpers) {
    const fileToastr = downloadFeedbackToastr.open({ type: "xml" });
    helpers.startFetching();
    const { url, name } = orderFileFactory.orderXml(order);
    const response = await fileDownloader({
      onProgress: fileToastr.updateProgress,
      url,
      name,
      type: "xml",
    });
    helpers.stopFetching();
    if (response.status === "success") {
      fileToastr.lazyClose();
    }
  }

  async function handleSendEmail(
    helpers: StatusHandlerHelpers,
    confirmationDeadline: PostDeadline | null = null,
  ) {
    if (!route) return;
    helpers.startFetching();
    const [, error, { rawError }] = await postRouteEmail(route.id, {
      force: false,
      deadline: confirmationDeadline,
    });
    helpers.stopFetching();
    if (!error) {
      toastr.open({
        type: "success",
        title: "Udało się!",
        text: "Wiadomość została wysłana",
      });
      smsModal.close();
      emailForAllConfirmationDateModal.close();
    } else {
      if (error.message === "noEmail") {
        confirmModal.open({
          text: (
            <div>
              <div>Poniższe zamówienia nie mają adresu email:</div>
              <span>{rawError.orders.map((orderSignature: string) => orderSignature)}</span>
              <div>czy pomimo tego chcesz wysłać wiadomość email do pozostałych zamówień?</div>
            </div>
          ),
          confirmText: "Wyślij",
          callback: () => postRouteEmail(route.id, { force: true, deadline: confirmationDeadline }),
        });
      } else {
        toastr.open({
          type: "failure",
          title: "Oj, coś nie tak.",
          text: getAnyErrorKey(error),
        });
      }
    }
  }

  async function handleSendSms(
    message: string | null,
    helpers: StatusHandlerHelpers,
    force: { resendSms: boolean; sendSms: boolean; resendUnconfirmed: boolean },
    confirmationDeadline: PostDeadline | null = null,
  ) {
    if (!route) return;
    helpers.startFetching();
    const [, error, { rawError }] = await postRouteSms(route.id, {
      message,
      force,
      deadline: confirmationDeadline,
    });
    helpers.stopFetching();
    if (!error) {
      toastr.open({
        type: "success",
        title: "Udało się!",
        text: "Wiadomość została wysłana",
      });
      smsModal.close();
      confirmationDateModal.close();
      singleSmsConfirmationDateModal.close();
    } else {
      const typedRawError = rawError as PostRouteSmsErrorResponse;

      if (Array.isArray(rawError)) {
        const filteredErrorMessages = typedRawError.filter(
          el => el.error !== "noPhone" && el.error !== "resendSms",
        ) as SmsError[];

        if (filteredErrorMessages.length) {
          const errorMessages = filteredErrorMessages.map(el => el.message);
          toastr.open({
            type: "failure",
            title: "Oj, coś nie tak.",
            text: (
              <div>
                {errorMessages.map(message => (
                  <div>{message}</div>
                ))}
              </div>
            ),
          });
          return;
        }

        const noPhoneError = typedRawError.find(el => el.error === "noPhone") as
          | NoPhoneError
          | undefined;
        const resendSmsError = typedRawError.find(el => el.error === "resendSms") as
          | SmsError
          | undefined;

        if (noPhoneError && resendSmsError) {
          confirmModal.open({
            text: (
              <div className="mb-4">
                <div className={styles.errorLabel}>1. Brak numerów telefonu</div>
                <div className="text-color-grey">Poniższe zamówienia nie mają numeru telefonu:</div>
                {noPhoneError.orders.map((signature, index) => (
                  <span key={signature}>
                    {signature}
                    {index !== noPhoneError.orders.length + 1 && ","}{" "}
                  </span>
                ))}
                <div className="mb-4">
                  Czy pomimo tego chcesz wysłać wiadomość SMS do pozostałych zamówień?
                </div>
                <div className={styles.errorLabel}>2. Powtórne powiadomienie</div>
                <div>{resendSmsError.message}</div>
              </div>
            ),
            confirmText: "Wyślij wiadomość",
            callback: () =>
              handleSendSms(
                message,
                helpers,
                {
                  resendSms: true,
                  sendSms: true,
                  resendUnconfirmed: force.resendUnconfirmed,
                },
                confirmationDeadline,
              ),
          });
          return;
        }

        if (noPhoneError) {
          confirmModal.open({
            text: (
              <div>
                <div className={styles.errorLabel}>Brak numerów telefonu</div>
                <div className="text-color-grey">Poniższe zamówienia nie mają numeru telefonu:</div>
                {noPhoneError.orders.map((signature, index) => (
                  <span>
                    {signature}
                    {index !== noPhoneError.orders.length + 1 && ","}{" "}
                  </span>
                ))}
                <div className="mb-4">
                  Czy pomimo tego chcesz wysłać wiadomość SMS do pozostałych zamówień?
                </div>
              </div>
            ),
            confirmText: "Wyślij wiadomość",
            callback: () =>
              handleSendSms(message, helpers, {
                resendSms: false,
                sendSms: true,
                resendUnconfirmed: force.resendUnconfirmed,
              }),
          });
          return;
        }

        if (resendSmsError) {
          confirmModal.open({
            text: (
              <div className="mb-3">
                <div className={styles.errorLabel}>Powtórne powiadomienie</div>
                <div>{resendSmsError.message}</div>
              </div>
            ),
            confirmText: "Wyślij wiadomość",
            callback: () =>
              handleSendSms(
                message,
                helpers,
                {
                  resendSms: true,
                  sendSms: false,
                  resendUnconfirmed: force.resendUnconfirmed,
                },
                confirmationDeadline,
              ),
          });
          return;
        }
      } else {
        toastr.open({
          type: "failure",
          title: "Oj, coś nie tak.",
          text: getAnyErrorKey(error),
        });
      }
    }
  }
  async function handleSendSingleSms(
    order: RouteOrder,
    message: string | null,
    helpers: StatusHandlerHelpers,
    force: { resendSms: boolean; sendSms: boolean; resendUnconfirmed: boolean },
    confirmationDeadline: PostDeadline | null = null,
  ) {
    if (!order) return;
    helpers.startFetching();
    const [, error, { rawError }] = await postSingleRouteSms(order.id, confirmationDeadline);
    helpers.stopFetching();
    if (!error) {
      toastr.open({
        type: "success",
        title: "Udało się!",
        text: "Wiadomość została wysłana",
      });
      smsModal.close();
      confirmationDateModal.close();
      singleSmsConfirmationDateModal.close();
    } else {
      const typedRawError = rawError as PostRouteSmsErrorResponse;

      if (Array.isArray(rawError)) {
        const filteredErrorMessages = typedRawError.filter(
          el => el.error !== "noPhone" && el.error !== "resendSms",
        ) as SmsError[];

        if (filteredErrorMessages.length) {
          const errorMessages = filteredErrorMessages.map(el => el.message);
          toastr.open({
            type: "failure",
            title: "Oj, coś nie tak.",
            text: (
              <div>
                {errorMessages.map((message, index) => (
                  <div key={index}>{message}</div>
                ))}
              </div>
            ),
          });
          return;
        }

        const noPhoneError = typedRawError.find(el => el.error === "noPhone") as
          | NoPhoneError
          | undefined;
        const resendSmsError = typedRawError.find(el => el.error === "resendSms") as
          | SmsError
          | undefined;

        if (noPhoneError && resendSmsError) {
          confirmModal.open({
            text: (
              <div className="mb-4">
                <div className={styles.errorLabel}>1. Brak numerów telefonu</div>
                <div className="text-color-grey">Poniższe zamówienia nie mają numeru telefonu:</div>
                {noPhoneError.orders.map((signature, index) => (
                  <span key={signature}>
                    {signature}
                    {index !== noPhoneError.orders.length + 1 && ","}{" "}
                  </span>
                ))}
                <div className="mb-4">
                  Czy pomimo tego chcesz wysłać wiadomość SMS do pozostałych zamówień?
                </div>
                <div className={styles.errorLabel}>2. Powtórne powiadomienie</div>
                <div>{resendSmsError.message}</div>
              </div>
            ),
            confirmText: "Wyślij wiadomość",
            callback: () =>
              handleSendSms(
                message,
                helpers,
                {
                  resendSms: true,
                  sendSms: true,
                  resendUnconfirmed: force.resendUnconfirmed,
                },
                confirmationDeadline,
              ),
          });
          return;
        }

        if (noPhoneError) {
          confirmModal.open({
            text: (
              <div>
                <div className={styles.errorLabel}>Brak numerów telefonu</div>
                <div className="text-color-grey">Poniższe zamówienia nie mają numeru telefonu:</div>
                {noPhoneError.orders.map((signature, index) => (
                  <span>
                    {signature}
                    {index !== noPhoneError.orders.length + 1 && ","}{" "}
                  </span>
                ))}
                <div className="mb-4">
                  Czy pomimo tego chcesz wysłać wiadomość SMS do pozostałych zamówień?
                </div>
              </div>
            ),
            confirmText: "Wyślij wiadomość",
            callback: () =>
              handleSendSms(
                message,
                helpers,
                {
                  resendSms: false,
                  sendSms: true,
                  resendUnconfirmed: force.resendUnconfirmed,
                },
                confirmationDeadline,
              ),
          });
          return;
        }

        if (resendSmsError) {
          confirmModal.open({
            text: (
              <div className="mb-3">
                <div className={styles.errorLabel}>Powtórne powiadomienie</div>
                <div>{resendSmsError.message}</div>
              </div>
            ),
            confirmText: "Wyślij wiadomość",
            callback: () =>
              handleSendSms(
                message,
                helpers,
                {
                  resendSms: true,
                  sendSms: false,
                  resendUnconfirmed: force.resendUnconfirmed,
                },
                confirmationDeadline,
              ),
          });
          return;
        }
      } else {
        toastr.open({
          type: "failure",
          title: "Oj, coś nie tak.",
          text: getAnyErrorKey(error),
        });
      }
    }
  }

  async function handleDownloadRoutePdf(route: Route, helpers: StatusHandlerHelpers) {
    const fileToastr = downloadFeedbackToastr.open({ type: "pdf" });
    helpers.startFetching();
    const { url, name } = routeFileFactory.routeGroupedProtocolsPdf(route);
    const response = await fileDownloader({
      onProgress: fileToastr.updateProgress,
      url,
      name,
      type: "pdf",
    });
    helpers.stopFetching();
    if (response.status === "success") {
      fileToastr.lazyClose();
    } else {
      fileToastr.close();
      toastr.open({
        type: response.httpStatus === 400 ? "warning" : "failure",
        title: "Oj, coś nie tak.",
        text: getAnyErrorKey(response.error),
      });
    }
  }

  function pickOrdersIdsFromOrdersPositions(ordersPositions: Route["ordersPositions"]) {
    function isOrderPredicate(
      item: Route["ordersPositions"][0],
    ): item is OrderPositionTypes["order"] {
      return (item as OrderPositionTypes["order"]).type === "order";
    }
    return ordersPositions.filter(isOrderPredicate).map(order => Number(order.id));
  }

  const formatSignatureForPreview = (signature: string) => signature.replace(/\s|\//g, "-");

  if (!panelId) {
    return null;
  }

  if (error) {
    return (
      <DrawerRight>
        <CommonError text={getAnyErrorKey(error)} status={error.status} />;
      </DrawerRight>
    );
  }
  if (!route) {
    return (
      <DrawerRight>
        <MockupLoader on={inProgress} type="drawer" />
      </DrawerRight>
    );
  }

  return (
    <DrawerSection
      title={
        <div className="d-flex align-items-center justify-content-between w-100">
          Punkty dostawy{" "}
          <Button
            kind="secondary"
            className={styles.routeLink}
            size="small"
            as={Link}
            to={`/orders/source/list/${
              route.status === "SETTLED" ? "archive" : "active"
            }/all/route/${route.id}?page=1`}
          >
            Lista zamówień
            <img src={arrowImg} alt="" className="ml-2" />
          </Button>
        </div>
      }
    >
      {Boolean(route.ordersPositions.length) && (
        <>
          {hasSmsAutomaticallySendForOrdersOnRoute && (
            <div className="d-flex align-items-center mb-2">
              <StatusHandler>
                {helpers => (
                  <DriverLocationButton
                    kind="secondary-grey"
                    disabled={helpers.isFetching}
                    onClick={smsSchedulePreviewModal.open}
                    className={`mr-auto ${styles.driverLocationButton}`}
                  >
                    <div className="d-flex align-items-center">Harmonogram automatycznych SMS</div>
                  </DriverLocationButton>
                )}
              </StatusHandler>
            </div>
          )}
          <div className="d-flex align-items-center mb-2">
            <StatusHandler>
              {helpers => (
                <DriverLocationButton
                  kind="secondary-grey"
                  disabled={helpers.isFetching}
                  onClick={driverLocationModal.open}
                  className={`mr-auto ${styles.driverLocationButton}`}
                >
                  <div className="d-flex align-items-center">Gdzie jest kierowca?</div>
                </DriverLocationButton>
              )}
            </StatusHandler>
            <Button
              kind="secondary-grey"
              size="small"
              title="Pokaż powiadomienia Email"
              onClick={emailNotificationsModal.open}
            >
              <div className="d-flex align-items-center">
                <img alt="" src={envelopeIcon} className="mr-2" />
                <span className="text-color-black">Pokaż powiadomienia Email</span>
                <img alt="" src={blueArrow} className="ml-2" />
              </div>
            </Button>
            <EmailNotificationsModal modal={emailNotificationsModal} />
          </div>
          <div className="d-flex justify-content-between align-items-center ">
            {hasSmsService && (
              <StatusHandler>
                {helpers => (
                  <Button
                    kind="secondary-stroke"
                    size="small"
                    disabled={helpers.isFetching}
                    onClick={() => {
                      if (!hasDeadlineConfirmationDate) {
                        handleSendSms(null, helpers, {
                          resendSms: false,
                          sendSms: false,
                          resendUnconfirmed: false,
                        });
                      } else {
                        confirmationDateModal.open();
                      }
                    }}
                  >
                    <img alt="" src={sendSmsIcon} className="mr-2" />
                    <span className={styles.smsBtn}>Wyślij SMS do wszystkich</span>
                  </Button>
                )}
              </StatusHandler>
            )}
            {confirmationDateModal.isOpen && (
              <ConfirmationDateModal
                close={confirmationDateModal.close}
                handleSendSms={handleSendSms}
                options={options}
              />
            )}

            <div className="ml-auto d-flex align-items-center">
              <Button
                kind="secondary"
                size="small"
                title="Podgląd wszystkich punktów"
                onClick={conversationModal.open}
              >
                Powiadomienia
              </Button>
              <ConversationModal modal={conversationModal} />
              <div className="border-left pl-1 ml-1">
                <Button
                  kind="secondary"
                  size="round-s"
                  rel="noopener noreferrer"
                  href={`${DOMAIN}routes/grouped-protocols-pdf/${
                    route.id
                  }/${formatSignatureForPreview(route.signature)}`}
                  target="_blank"
                  as="a"
                  title="Podgląd wszystkich punktów"
                >
                  <img alt="" src={viewPdfIcon} />
                </Button>
                <StatusHandler>
                  {helpers => (
                    <Button
                      kind="secondary"
                      size="round-s"
                      onClick={() => handleDownloadRoutePdf(route, helpers)}
                      title="Pobierz PDF wszystkich punktów"
                      disabled={helpers.isFetching}
                    >
                      <img alt="" src={printPdfIcon} />
                    </Button>
                  )}
                </StatusHandler>
                <Button
                  kind="secondary"
                  size="round-s"
                  rel="noopener noreferrer"
                  href={`${DOMAIN}utils/labels/route/${route.id}/${formatSignatureForPreview(
                    route.signature,
                  )}`}
                  target="_blank"
                  as="a"
                  title="Podgląd etykiet zamówień"
                >
                  <img alt="" src={viewLabelImg} />
                </Button>
              </div>
              <span className="border-left pl-1 ml-1">
                <Button
                  kind="secondary"
                  size="round-s"
                  as={Link}
                  to={`/routes/creator/${panelId}`}
                  title="Edytuj trasę"
                >
                  <img alt="" src={editIcon} />
                </Button>
              </span>
            </div>
          </div>
          <div className="d-flex">
            {hasSmsService && (
              <>
                <Button
                  kind="link"
                  size="small"
                  className="mb-2 mt-1"
                  onClick={() => smsModal.open("ROUTE")}
                >
                  <span className={styles.smsBtn}>Utwórz i wyślij własny sms</span>
                </Button>
                <CustomSMSModal
                  close={smsModal.close}
                  isOpen={smsModal.isOpen}
                  kind={smsModal.state}
                  onSubmit={(message, helpers, force, confirmationDeadline) => {
                    assertIsDefined(force);
                    handleSendSms(message, helpers, force, confirmationDeadline);
                  }}
                  options={options}
                />
              </>
            )}
            <StatusHandler>
              {helpers => (
                <Button
                  kind="link"
                  size="small"
                  className="mb-2 mt-1"
                  disabled={helpers.isFetching}
                  onClick={() => {
                    if (!hasDeadlineConfirmationDate) {
                      handleSendEmail(helpers);
                    } else {
                      emailForAllConfirmationDateModal.open();
                    }
                  }}
                >
                  <span className={styles.smsBtn}>Wyślij email do wszystkich</span>
                </Button>
              )}
            </StatusHandler>
            {emailForAllConfirmationDateModal.isOpen && (
              <EmailForAllConfirmationDateModal
                close={emailForAllConfirmationDateModal.close}
                handleSendEmail={handleSendEmail}
                options={options}
              />
            )}
          </div>
          <PostDeliveryInfo routeId={route.id} ordersCount={route.orders.length} />
        </>
      )}

      {orderArrayByIdSchema(
        route.orders,
        pickOrdersIdsFromOrdersPositions(route.ordersPositions),
      ).map((order, index) => (
        <div key={order.id}>
          {isAwaitingWarehouseDelivery(order) && (
            <div className="d-flex align-items-center">
              <img
                alt="Oczekuje na dostarczenie"
                className="mr-1"
                src={getMarkerIcon({
                  id: order.id,
                  hasUpholstery: order.hasUpholstery,
                  type: order.type,
                  leftDays: order.leftDays,
                  priority: order.priority,
                  warehouse: order.warehouse,
                  numberOfDaysFromCreatedDate: order.numberOfDaysFromCreatedDate,
                  warehouseDeliveryDetails: order.warehouseDeliveryDetails,
                  hideOnMapTo: order.hideOnMapTo,
                  hideUntilIssueIsSolved: order.hideUntilIssueIsSolved,
                  isHidden: order.isHidden,
                })}
                style={{ width: "15px", height: "15px" }}
              />
              <span className="fs-10">Oczekuje na dostarczenie do magazynu</span>
              {hasDaysToDeliveryLeft(order) && (
                <div className="d-flex align-items-center">
                  <span>&nbsp;</span>
                  {hasMoreThanZeroDaysToDeliveryLeft(order) && (
                    <span className="fs-10">
                      ({order.warehouseDeliveryDetails.daysLeftToDelivery}d)
                    </span>
                  )}
                  {hasZeroDaysToDeliveryLeft(order) && <span className="fs-10">(dzisiaj)</span>}
                  {hasLessThanZeroDaysToDeliveryLeft(order) && (
                    <span className="fs-10">
                      ({order.warehouseDeliveryDetails.daysLeftToDelivery}d)
                    </span>
                  )}
                </div>
              )}
            </div>
          )}
          <div
            className={cx("d-flex align-items-center justify-content-between mb-2", {
              "text-color-grey": isAwaitingWarehouseDelivery(order),
            })}
          >
            <div className="d-flex align-items-center justify-content-between width-available w-100 mb-2">
              <div className="mr-2 fs-12 text-color-grey">{index + 1}.</div>
              <div className="w-100">
                <div className="d-flex align-items-center">
                  <div className="d-flex flex-column flex-1">
                    <div>
                      {order.delivery.city}, {order.delivery.street}
                    </div>
                    <div>{order.delivery.postCode}</div>
                    <div className={styles.client}>
                      {order.customer
                        ? order.client.companyName
                        : `${order.client.firstName} ${order.client.lastName}`}
                    </div>
                  </div>

                  {singleSmsConfirmationDateModal.isOpen && (
                    <SingleSmsConfirmationDateModal
                      close={singleSmsConfirmationDateModal.close}
                      handleSendSingleSms={handleSendSingleSms}
                      options={options}
                      order={orderToNotify ? orderToNotify : order}
                    />
                  )}
                </div>

                <div className="d-flex align-items-center gap-2">
                  <Link className={styles.orderSignature} to={getOrderRedirectUrl(order)}>
                    {order.signature}
                  </Link>
                  {order.salesAccount &&
                    showSalesAccountLabel(
                      configuration.customerSalesAccountLabelsInOrderList,
                      order.customer,
                      order.salesAccount,
                    ) && (
                      <div
                        className={styles.label}
                        style={{
                          backgroundColor: order.salesAccount.color,
                        }}
                      >
                        <span style={{ color: order.salesAccount.textColor }}>
                          {order.salesAccount.name}
                        </span>
                      </div>
                    )}
                  {order.salesAccount &&
                    order.customer &&
                    showCustomerLabel(
                      configuration.customerSalesAccountLabelsInOrderList,
                      order.customer,
                      order.salesAccount,
                    ) && (
                      <div
                        className={cx(styles.label, {
                          "ml-2": showSalesAccountLabel(
                            configuration.customerSalesAccountLabelsInOrderList,
                            order.customer,
                            order.salesAccount,
                          ),
                        })}
                        style={{
                          backgroundColor: order.customer.color,
                        }}
                      >
                        <span style={{ color: order.customer.textColor }}>
                          {order.customer.name.toLowerCase()}
                        </span>
                      </div>
                    )}
                </div>
              </div>
              <div className="d-flex flex-column gap-2">
                {hasSmsService && (
                  <StatusHandler>
                    {helpers => (
                      <Button
                        kind="secondary-stroke"
                        size="btnSizeSmall"
                        disabled={helpers.isFetching}
                        onClick={() => {
                          if (!hasDeadlineConfirmationDate) {
                            handleSendSingleSms(order, null, helpers, {
                              resendSms: false,
                              sendSms: false,
                              resendUnconfirmed: false,
                            });
                          } else {
                            setOrderToNotify(order);
                            singleSmsConfirmationDateModal.open();
                          }
                        }}
                      >
                        <div className="d-flex align-items-center">
                          <img alt="" src={sendSmsIcon} className="mr-2" />
                          <span
                            className={cx(styles.smsBtn, "fs-14", {
                              [styles.smsBtnGrey]: isAwaitingWarehouseDelivery(order),
                            })}
                          >
                            Wyślij SMS
                          </span>
                        </div>
                      </Button>
                    )}
                  </StatusHandler>
                )}

                <SendEmailButton orderId={order.id} />
              </div>
              <div className={styles.deliveryTime}>
                <span>czas dostawy</span>
                <strong>
                  {order.delivery.plannedDeliveryTime
                    ? dateFns.format(
                        new Date(order.delivery.plannedDeliveryTime),
                        "dd.MM.yyyy HH:mm",
                      )
                    : "---"}
                </strong>
                {order.delivery.minimumCollectionHour && order.delivery.maximumCollectionHour && (
                  <strong>
                    {order.delivery.minimumCollectionHour} - {order.delivery.maximumCollectionHour}
                  </strong>
                )}
              </div>
            </div>
            <div className="ml-2">
              <div className="d-flex align-items-center justify-content-end">
                <span
                  title={
                    order.hasEmailSent
                      ? "Wysłano powiadomienie Email"
                      : "Nie wysłano powiadomienia Email"
                  }
                  className={cx(styles.smsNotification, order.hasEmailSent ? "bgPurple" : "bgGrey")}
                >
                  <img alt="" src={envelopeIcon} />
                </span>
                {hasSmsService && (
                  <>
                    <span
                      title={
                        order.hasSmsSent
                          ? "Wysłano powiadomienie SMS"
                          : "Nie wysłano powiadomienia SMS"
                      }
                      className={cx(
                        styles.smsNotification,
                        order.hasSmsSent ? "bgPurple" : "bgGrey",
                      )}
                    >
                      <img alt="" src={sendSmsIcon} />
                    </span>
                    <span
                      title={
                        order.isDeliveryDateConfirm
                          ? "Data dostawy potwierdzona"
                          : "Data dostawy niepotwierdzona"
                      }
                      className={cx(
                        styles.smsNotification,
                        order.isDeliveryDateConfirm ? "bgBlue" : "bgGrey",
                      )}
                    >
                      <img alt="" src={deliveryConfirmedIcon} />
                    </span>
                  </>
                )}
              </div>

              <div className="d-flex ml-2">
                <ClickSessionMemorizer sessionId="downloaded-route-orders-label-pdf">
                  {({ isClicked, setClicked, clickedClassName }) => (
                    <Button
                      kind="secondary"
                      size="round-s"
                      rel="noopener noreferrer"
                      href={`${DOMAIN}utils/labels/order/${order.id}/${formatSignatureForPreview(
                        order.signature,
                      )}`}
                      target="_blank"
                      as="a"
                      className={isClicked(order.id) ? clickedClassName : undefined}
                      title="Podgląd etykiet zamówienia"
                      onClick={() => {
                        setClicked(order.id);
                      }}
                    >
                      <img alt="" src={viewLabelImg} />
                    </Button>
                  )}
                </ClickSessionMemorizer>
                <ClickSessionMemorizer sessionId="downloaded-route-orders-xml">
                  {({ isClicked, setClicked, clickedClassName }) => (
                    <StatusHandler>
                      {helpers => (
                        <Button
                          kind="secondary"
                          size="round-s"
                          disabled={helpers.isFetching}
                          onClick={() => {
                            handleDownloadOrderXml(order, helpers);
                            setClicked(order.id);
                          }}
                          className={isClicked(order.id) ? clickedClassName : undefined}
                          title="Pobierz XML zamówienia"
                        >
                          <img alt="" src={printOrderXmlIcon} />
                        </Button>
                      )}
                    </StatusHandler>
                  )}
                </ClickSessionMemorizer>
                <ClickSessionMemorizer sessionId="downloaded-route-orders-pdf">
                  {({ isClicked, setClicked, clickedClassName }) => (
                    <StatusHandler>
                      {helpers => (
                        <Button
                          kind="secondary"
                          size="round-s"
                          disabled={helpers.isFetching}
                          onClick={() => {
                            handleDownloadOrderPdf(order, helpers);
                            setClicked(order.id);
                          }}
                          title="Pobierz PDF zamówienia"
                          className={isClicked(order.id) ? clickedClassName : undefined}
                        >
                          <img alt="" src={printOrderPdfIcon} />
                        </Button>
                      )}
                    </StatusHandler>
                  )}
                </ClickSessionMemorizer>
              </div>
            </div>
          </div>
        </div>
      ))}

      {driverLocationModal.isOpen && (
        <DriverLocationModal modal={driverLocationModal} routeId={route.id} />
      )}
      {smsSchedulePreviewModal.isOpen && (
        <AutomaticSmsScheduleModal close={smsSchedulePreviewModal.close} routeId={route.id} />
      )}
    </DrawerSection>
  );
};

import { routeKeys } from "api/keys";
import { EmailMessage } from "api/messages/models";
import { queryFetch } from "apiConnectors/queryFetch";
import { CommentType } from "components/common";
import { transformMeta } from "hooks/apiPrimitives";
import { ApiFetcher } from "hooks/createApiQuery";
import { dateFns, omit, queryString } from "utilities";
import { Assign } from "utility-types";
import apiFetch, { tokenRefreshMiddleware } from "../../apiConnectors/fetchConnector";
import { PartialOf } from "../../typeUtilities";
import { Order, OrderCategory } from "../orders/models";
import { Pagination } from "../types";
import { routesKeys } from "./keys";
import {
  AcceptReceptionByProduct,
  AddIndexPayload,
  AutomaticSmsSchedule,
  HideOrderOnMap,
  HistoryEntry,
  OpenDocumentPayload,
  PinToRoute,
  PostDeadline,
  RampLoadingStatus,
  ReceptionEntriesPayload,
  Route,
  RouteDeliveryPointsLinks,
  RouteDriverScheduleItem,
  RouteEmails,
  RouteListItem,
  RouteMessages,
  RouteOrdersComments,
  RouteProgress,
  RoutePutSchema,
  RouteSettledDetails,
  RouteSettlementSchema,
  SimplifiedRoute,
} from "./models";
import { CenterPoint } from "api/other/models";

const api = tokenRefreshMiddleware(apiFetch);

export const getRoute = (id: string | number): ApiFetcher<Route> => ({
  key: routesKeys.route(String(id)),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/routes/items/" + id,
    }),
  globalOptions: {
    enabled: Boolean(id),
    select: transformMeta,
  },
});

export const getRoutes = (search: string = ""): ApiFetcher<Pagination<RouteListItem>> => ({
  key: routesKeys.routes(search),
  fetcher: () =>
    queryFetch<Pagination<RouteListItem>>({
      method: "GET",
      url: "/routes/items" + search,
    }),
});

export const getReceptionEntries = (search: string = "") => ({
  key: routesKeys.receptionEntries(search),
  fetcher: () =>
    queryFetch<Pagination<HistoryEntry>>({
      method: "GET",
      url: "/receptions/entries" + search,
    }),
});

export const getRouteDeliveryPointsLinks = (
  id: string | number,
): ApiFetcher<RouteDeliveryPointsLinks[]> => ({
  key: routesKeys.routeDeliveryPointsLinks(String(id)),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/routes/delivery-points-link/" + id,
    }),
});

export const getPinToRouteHistory = (search: string = "") => ({
  key: routesKeys.pinToRouteHistory(search),
  fetcher: () =>
    queryFetch<Pagination<PinToRoute>>({
      method: "GET",
      url: "/orders/pin-to-route-history" + search,
    }),
});

export const postRoute = (data: any) =>
  queryFetch<Route>({
    method: "POST",
    url: "/routes/items",
    data,
  });

export const getSettledRoute = (id: string | number) => ({
  key: routesKeys.route(String(id)),
  fetcher: () =>
    queryFetch<Route>({
      method: "GET",
      url: "/routes/settled-snapshots/" + id,
    }),
  globalOptions: {
    enabled: Boolean(id),
    select: transformMeta,
  },
});

export const getRouteToSettle = (id: string | number) =>
  api<RouteSettlementSchema>({
    method: "GET",
    url: "/routes/to-settle/" + id,
  });

export const getRoutesSimplifiedItems = (search: string = "", abortToken?: string) =>
  api<Pagination<SimplifiedRoute>>({
    method: "GET",
    url: "/routes/simplified-items" + search,
    abortToken,
  });

export const getRouteSettledDetails = (id: string | number) =>
  api<RouteSettledDetails>({
    method: "GET",
    url: "/routes/settled/" + id,
  });

export const patchRoute = (
  data: PartialOf<Assign<Route, { driver: number | null; startingPoint: number | null }>>,
  id: string | number,
) =>
  api<Route>({
    method: "PATCH",
    url: "/routes/items/" + id,
    data,
  });

export const patchHideOrderOnMap = (data: PartialOf<HideOrderOnMap>, id: number) =>
  queryFetch<void>({
    method: "PATCH",
    url: "/routes/hide-order-on-map/" + id,
    data,
  });

export const patchReverseHidingPointOnMap = (data: { orderId: number }) =>
  queryFetch<void>({
    method: "PATCH",
    url: "/routes/reverse-hiding-point-on-map",
    data,
  });

export const patchRoute_query = (
  data: PartialOf<Assign<Route, { driver: number | null; startingPoint: number | null }>>,
  id: string | number,
) =>
  queryFetch<Route>({
    method: "PATCH",
    url: "/routes/items/" + id,
    data,
  });

export const putRoute = (data: RoutePutSchema, id: string | number) =>
  queryFetch<{ categories: OrderCategory[] }>({
    method: "PUT",
    url: "/routes/global-map-update/" + id,
    data,
  });

export const deleteRoute = (id: number) =>
  queryFetch<void>({
    method: "DELETE",
    url: "/routes/items/" + id,
  });

export const postSettledRoute = (data: {
  route: number;
  driver: number;
  amount: number;
  currency: string;
  delivered: { [key: string]: boolean };
  visited: { [key: string]: boolean };
  summary: {
    numPointsDelivered: number;
    numPointsNotDelivered: number;
    numPointsVisited: number;
    deliveredPointsTotalAmount: number;
    notDeliveredPointsTotalAmount: number;
    onlinePaymentTotalAmount: number;
    cashToCollect: number;
  };
}) =>
  api<Route>({
    method: "POST",
    url: "/routes/settled",
    data,
  });

export const postRouteSms = (
  id: Route["id"],
  data: {
    message: string | null;
    force: {
      sendSms: boolean;
      resendSms: boolean;
    };
    deadline: PostDeadline | null;
  },
) =>
  api<{ message: string }[]>({
    method: "POST",
    url: "/routes/send-sms/" + id,
    data,
  });

export const postSingleRouteSms = (orderId: number, deadline: PostDeadline | null) =>
  api<{ message: string }>({
    method: "POST",
    url: "orders/send-route-sms",
    data: { orderId, deadline },
  });

export const postSingleRouteEmail = (data: {
  orderId: Order["id"];
  resend: boolean;
  deadline: PostDeadline | null;
}) =>
  queryFetch<{
    message: string;
    content: Pick<EmailMessage, "body" | "id" | "failureReason" | "subject" | "status" | "order">;
  }>({
    method: "POST",
    url: "/orders/send-email",
    data,
  });

export const postRouteEmail = (
  id: Route["id"],
  data: { force: boolean; deadline: PostDeadline | null },
) =>
  api<{ message: string }>({
    method: "POST",
    url: "/routes/send-email/" + id,
    data,
  });

export const postReceptionDocument = (data: {
  orderGroup?: number;
  route?: number;
  productCategory: number | null;
}) =>
  queryFetch<OpenDocumentPayload>({
    method: "POST",
    url: "/receptions/items",
    data,
  });

export const postAcceptReceptionByProduct = (data: AcceptReceptionByProduct) =>
  queryFetch<{ created: boolean; url: string }>({
    method: "POST",
    url: "/receptions/bulk-create",
    data,
  });

export const closeReceptionDocument = (id: string, data: { isFinished: boolean }) =>
  queryFetch<ReceptionEntriesPayload>({
    method: "PATCH",
    url: "/receptions/items/" + id,
    data,
  });

export const getIndexesForReception = (id: string) => ({
  key: routesKeys.receptionIndexes(id),
  fetcher: () =>
    queryFetch<ReceptionEntriesPayload>({
      method: "GET",
      url: "/receptions/items/" + id,
    }),
});

export const getRampLoadingStatus = (id: number | string): ApiFetcher<RampLoadingStatus[]> => ({
  key: routesKeys.rampLoadingStatus(String(id)),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/routes/ramp-loading-status/" + id,
    }),
});

export const postReceptionEntry = (data: { reception: string; index: number; quantity: number }) =>
  queryFetch<AddIndexPayload>({
    method: "POST",
    url: "/receptions/entries",
    data,
  });

export const patchUpdateItemsDeliveryAmount = (data: {}) =>
  queryFetch<void>({
    method: "PATCH",
    url: "/orders/update-items-delivery-amount",
    data,
  });

export const postCarrierOrder = (data: { route: number }) =>
  queryFetch<void>({
    method: "POST",
    url: "/carrier-orders/generate",
    data,
  });

export const postPrintReceiptsForRoutes = (data: { routes: number[] }) =>
  queryFetch<{ message: string }>({
    method: "POST",
    url: "/finances/trading-documents/print-receipts-for-routes",
    data,
  });

export const getRouteDriversSchedule = (search: string) =>
  api<RouteDriverScheduleItem[]>({
    method: "GET",
    url: "/routes/drivers-schedule" + search,
  });

export const getRouteProgress = (routeId: string | number): ApiFetcher<RouteProgress> => ({
  key: routeKeys.driverLocations(String(routeId)),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/routes/progress/" + routeId,
    }),
});

export const getRouteAutomaticSmsSchedule = (
  search: string = "",
): ApiFetcher<Pagination<AutomaticSmsSchedule>> => ({
  key: routeKeys.automaticSmsSchedule(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/call-center/routes-scheduled-sms" + search,
    }),
});

export const postUserCenterPoint = (data: Omit<CenterPoint, "id">) =>
  queryFetch<void>({
    method: "POST",
    url: "/center-points/items",
    data,
  });

export const removeDeliveryConfirmationForRoutes = (data: { routes: number[] }) =>
  queryFetch<void>({
    method: "PATCH",
    url: "/orders/remove-delivery-confirmation",
    data,
  });

export const removeDeliveryConfirmationForOrders = (data: { orders: number[] }) =>
  queryFetch<void>({
    method: "PATCH",
    url: "/orders/remove-delivery-confirmation",
    data,
  });

export const patchUserCenterPoint = (data: CenterPoint) =>
  queryFetch<void>({
    method: "PATCH",
    url: "/center-points/items/" + data.id,
    data,
  });

export const routeFileFactory = (() => {
  function formatSignature(signature: string) {
    return signature.replace(/\//g, "-");
  }
  function getDate() {
    return dateFns.format(new Date(), "dd-MM-yyyy-HH-mm");
  }
  return {
    routePdf: (route: Pick<Route, "id" | "signature">, search?: string) => ({
      url: `/routes/items/${route.id}/route-pdf` + search,
      name: `${formatSignature(route.signature)}-${getDate()}_z-cenami`,
    }),
    routesReport: (startDate: string, endDate: string) => ({
      url: `/routes/reports${queryString.stringify({
        startDate,
        endDate,
      })}`,
      name: `raport_z_tras_${startDate}_${endDate}`,
    }),
    routeGroupedProtocolsPdf: (route: Pick<Route, "id" | "signature">) => ({
      url: `/routes/items/${route.id}/grouped-protocols-pdf`,
      name: `${formatSignature(route.signature)}-${getDate()}_wszystkich-punktów`,
    }),
    routeOrdersXml: (route: Pick<Route, "id" | "signature">) => ({
      url: `/routes/items/${route.id}/xml`,
      name: `${formatSignature(route.signature)}-${getDate()}`,
    }),
    routeProductionPdf: (route: Pick<Route, "id" | "signature">) => ({
      url: `/routes/items/${route.id}/production-pdf`,
      name: `${formatSignature(route.signature)}-${getDate()}`,
    }),
    routeOrdersPdf: (route: Pick<Route, "id" | "signature">) => ({
      url: `/routes/get-detailed-pdfs-from-route/${route.id}`,
      name: `${formatSignature(route.signature)}-${getDate()}`,
    }),
    routeLabelPdf: (route: Pick<Route, "id" | "signature">) => ({
      url: "/utils/print?route=" + route.id,
      name: `${formatSignature(route.signature)}-${getDate()}`,
    }),
    routeItemsSummary: (route: Pick<Route, "id" | "signature">) => ({
      url: `/routes/items/${route.id}/items-summary-pdf`,
      name: `${formatSignature(route.signature)}-${getDate()}`,
    }),
    routePackingSlip: (route: Pick<Route, "id" | "signature">) => ({
      url: `utils/packing-slip/route/${route.id}/download`,
      name: `${formatSignature(route.signature)}-${getDate()}`,
    }),
    routePackingSlipGroupedByOrders: (route: Pick<Route, "id" | "signature">) => ({
      url: `utils/packing-slip-grouped-by-orders/route/${route.id}/download`,
      name: `${formatSignature(route.signature)}-${getDate()}`,
    }),
    routeDeliveryAmountSummary: (route: Pick<Route, "id" | "signature">) => ({
      url: `utils/document-with-delivery-amount/route/${route.id}`,
      name: `${formatSignature(route.signature)}-${getDate()}`,
    }),
    routeOrdersCSV: (route: Pick<Route, "id" | "signature">) => ({
      url: `shipping/route/${route.id}/generate-csv`,
      name: `zamówienia-dla-trasy_${formatSignature(route.signature)}_${getDate()}`,
    }),
    routeSettlementCSV: (route: Pick<Route, "id" | "signature">) => ({
      url: `shipping/route/${route.id}/generate-detailed-csv`,
      name: `rozliczenie-dla-trasy-${formatSignature(route.signature)}-${getDate()}`,
    }),
    routeDeliveryPointsCSV: (route: Pick<Route, "id" | "signature">) => ({
      url: `routes/delivery-points-csv/${route.id}`,
      name: `lista_punktów-trasy-${formatSignature(route.signature)}-${getDate()}`,
    }),
  };
})();

// COMMENTS
export const postComment = (data: { body: string; route: number }) =>
  api<CommentType>({
    method: "POST",
    url: "/routes/comments",
    data,
  });

export const patchComment = (
  comment: Assign<Partial<CommentType>, { user?: number; id: number }>,
) =>
  api<CommentType>({
    method: "PATCH",
    url: "/routes/comments/" + comment.id,
    data: omit(comment, ["id"]),
  });

export const deleteComment = (id: number) =>
  api<void>({
    method: "DELETE",
    url: "/routes/comments/" + id,
  });

export const getComments = (search: string = "", abortToken?: string) =>
  api<Pagination<CommentType>>({
    method: "GET",
    url: "/routes/comments" + search,
    abortToken,
  });

export const getRouteOrdersComments = (route: number) => ({
  key: routeKeys.routeCreatorOrdersComments(String(route)),
  fetcher: () =>
    queryFetch<RouteOrdersComments>({
      method: "GET",
      url: "/routes/route-orders-comments/" + route,
    }),
});

export const getRouteSmsMessages = (id: string | number): ApiFetcher<RouteMessages> => ({
  key: routesKeys.routeMessages(String(id)),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/routes/messages/sms/" + id,
    }),
});

export const getRouteEmailsMessages = (id: string | number): ApiFetcher<RouteEmails> => ({
  key: routesKeys.routeEmails(String(id)),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/routes/messages/email/" + id,
    }),
});
